<template>
    <div>
        <div class='row'>
            <nav class='nav_side col-lg-2 col-md-3 col-sm-3'>
                <ul class="nav nav-pills">
                    <li>
                        <div class="col-xs-12 form-group">
                            <label>Start Date</label>
                            <DatePicker :inputAttributes="{class: 'form-control'}" v-model='startDate' />
                        </div>
                    </li>
                    <li>
                        <div class='col-xs-12 form-group'>
                            <label>End Date</label>
                            <DatePicker :inputAttributes="{class: 'form-control'}" v-model='endDate' />
                        </div>
                    </li>
                    <li>
                        <div class='col-xs-12 form-group'>
                            <label>Employee</label>
                            <Typeahead ref="employeeTypeahead" :cdi_type="'employee'" :displayValue='employee' v-bind:ID.sync='employeeId'/>
                        </div>
                    </li>
                    <li>
                        <div class='col-xs-12 form-group'>
                            <label>Location</label>
                            <select class="cdi_select form-control" v-model="location_id">
                                <option value="0">All</option>
                                <option v-for="[cdiLocationID, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="cdiLocationID" :value="cdiLocationID">{{cdiLocation}}</option>
                            </select>                        </div>
                    </li>
                    <li>
                        <div class='col-xs-12'>
                            <button class='btn btn-default' @click='filterAlerts'>Filter</button>
                        </div>
                    </li>
                </ul>
            </nav>
            <main class='col-lg-10 col-md-9 col-sm-9 left_gutter fill'>
                <h1>Alerts</h1>
                <div class='table-responsive'>
                    <table class='table'>
                        <thead>
                        <tr>
                            <th>Alert</th>
                            <th>Date and Time</th>
                            <th>Record</th>
                            <th>Read Time</th>
                            <th>Read</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for='(alert, index) in alerts' v-bind:key='index'>
                                <td>{{ alert.cdi_alert }}</td>
                                <td>{{ alert.cdi_alert_dt }}</td>
                                <td>{{ alert.record_type }} <router-link :to='alert.url'>{{ alert.record_id }}</router-link></td>
                                <td>{{ alert.read_dt }}</td>
                                <td v-if='alert.read_by_id'>{{ alert.read_by }}</td>
                                <td v-else> <button @click='markAsRead(alert.cdi_alert_id)' class='btn btn-default'>Mark as Read</button> </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import { myStore } from '@/store/My.store';
import DatePicker from "@/components/utils/DatePicker";
import Typeahead from "@/components/utils/typeaheads/Typeahead";
import cdiLocationCache from '@/cache/cdiLocation.cache.js';

export default {
    name: 'MyAlerts',
    components: {Typeahead, DatePicker},
    data() {
        return {
            startDate: new Date().toLocaleDateString(),
            endDate: new Date().toLocaleDateString(),
            employeeId: this.$appStore.state.user.userID,
            employee: this.$appStore.state.user.userName,
            location_id:  0,
            alerts: {},
            cache: {
                cdiLocationCache,
            },
        }
    },
    methods: {
        filterAlerts: function() {
            let params = {
                'params[datetimes][cdi_alert_dt_from]' : this.startDate,
                'params[datetimes][cdi_alert_dt_to]' : this.endDate,
                'params[user_id]' : this.employeeId,
                'params[location_id]': this.location_id,
            }
            myStore.staticApi('myuser/alerts_json', params).then(result => this.alerts = result)
        },
        markAsRead: function(alert_id) {
            myStore.staticApi('myuser/mark_alert_read', {'params' : alert_id}).then( () => this.filterAlerts())
        }
    },
    created() {
        this.filterAlerts();
    },
    mounted() {
        this.$refs.employeeTypeahead.$data.object = this.employee;
    }
}
</script>

<style scoped>
</style>